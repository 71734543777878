import React, {Component} from 'react';
import {API} from "../tools/api";
import '../styles/components/ErrorBoundary.less';
import {Button, Result} from 'antd';

class ErrorBoundary extends Component {
    state: {hasError: boolean};

    constructor(props: any) {
        super(props);
        // default has no error
        this.state = { hasError: false}
    }

    // for when an error occurs, set hasError state to true
    static getDerivedStateFromError(error: any){
        return { hasError: true };
    }

    // do something after error is caught
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // log this error in the debugLog
        try{
            new API().logError(error);
        } catch(err) {
            console.log(err);
        }
    }

    render() {
        // check if there's an error
        if (this.state.hasError) {
            return (
                <div className={'main-div'}>
                    <Result
                        status="500"
                        title="Oops..."
                        subTitle="Sorry, something went wrong here. Please refresh the page below."
                        extra={<Button type="primary" onClick={()=>window.location.reload()}>Refresh Page</Button>}
                    />
                </div>
            )
        }

        // when there's no error, just show the component
        return this.props.children;
    }
}

export default ErrorBoundary;